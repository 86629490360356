import React, { useState } from 'react';
import { css, styled } from 'styled-components';
import { breakpoints } from '../../styled/utils';
import { BreakpointKey, Breakpoints } from '../../types';
import NavItem from './NavItem';
import Text from '../Text';
import useOuterClick from '../../hooks/useOuterClick';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Link from '../Link';
import useVacancyCount from '../../hooks/useVacancyCount';
import Icon, { IconProps } from '../Icon';

interface NavProps {
  children?: React.ReactNode,
  direction: string,
  display?: Breakpoints,
}

interface StyledNavProps {
  $display?: Breakpoints,
  $direction: string,
}

const StyledNav = styled.nav<StyledNavProps>`
  display: flex;

  ${props => props.$display && Object.entries(props.$display).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      display: ${value};
    }
  `)}

  ${props => props.$direction === 'horizontal' && css`
    align-items: center;
    column-gap: var(--space-lg);
  `}

  ${props => props.$direction === 'vertical' && css`
    flex-direction: column;
    row-gap: var(--baseline);
  `}
`;

interface NavChildrenProps {
  $isOpen?: boolean,
}

const NavChildren = styled.ul<NavChildrenProps>`
  display: flex;
  flex-direction: column;
  z-index: 10;
  margin-top: var(--baseline-sm);

  @media ${breakpoints['lg']} {
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-iron-400);
    bottom: calc(var(--space-sm) * -1);
    box-shadow: var(--box-shadow);
    margin-top: 0;
    min-width: max-content;
    opacity: ${(props) => props.$isOpen ? '1' : '0'};
    pointer-events: ${(props) => props.$isOpen ? 'all' : 'none'};
    position: absolute;
    transition-duration: 250ms;
    transition-property: opacity;
    translate: 0 100%;
    width: 100%;
  }
  
  a {
    color: var(--color-downriver-400);
    padding-inline: var(--space-md);
    position: relative;
    display: flex;
    align-items: center;
    height: var(--space-xxl);
    
    @media ${breakpoints['lg']} {
      border-bottom: 1px solid var(--color-iron-300);
      height: var(--space-lg);
    }

    &.active {
      text-decoration-color: var(--color-pastel-green-400);
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-thickness: 1px;
      text-underline-offset: var(--space-md);

      @media ${breakpoints['lg']} {
        background-color: var(--color-iron-300);
        border-bottom: 1px solid var(--color-pastel-green-400);
        text-decoration: none;
      }
    }

    &:hover {
      @media ${breakpoints['lg']} {
        background-color: var(--color-iron-300);
      }
    }

    &::before {
      background-color: var(--color-pastel-green-400);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 1px;

      @media ${breakpoints['lg']} {
        display: none;
      }
    }
  }
`;

interface AngleIconProps extends IconProps {
  $isOpen?: boolean,
}

const AngleIcon = styled(Icon)<AngleIconProps>`
  display: none;

  @media ${breakpoints['lg']} {
    display: block;
    rotate: ${(props) => props.$isOpen ? '180deg' : '0'};
    transition-duration: 250ms;
    transition-property: rotate;
  }
`;

export function FooterNav({ direction, children, display }: NavProps) {

  return (
    <StyledNav $direction={direction} $display={display}>
      {children}
    </StyledNav>
  );
}

export default function Nav({ direction, children, display }: NavProps) {
  const [isOpen, setOpen] = useState(false);
  const showCareers = useVacancyCount();

  const ref = useOuterClick(() => {
    if (isOpen) {
      setOpen(false);
    }
  });

  return (
    <StyledNav $direction={direction} $display={display}>
      <NavItem as="div" onClick={() => setOpen(!isOpen)} ref={ref}>
        <Text en="Rust services" />
        <AngleIcon icon={faAngleDown} $isOpen={isOpen} />
        <NavChildren $isOpen={isOpen}>
          <li>
            <Link routeName="expertises.rust.explore-rust">Explore Rust</Link>
          </li>
          <li>
            <Link routeName="expertises.rust-engineering">Rust engineering</Link>
          </li>
          <li>
            <Link routeName="expertises.rust.training">Rust training</Link>
          </li>
        </NavChildren>
      </NavItem>
      <NavItem routeName="our-people"><Text en="Our people" /></NavItem>
      <NavItem routeName="our-work"><Text en="Our work" /></NavItem>
      <NavItem routeName="blog"><Text en="Blog" /></NavItem>
      {showCareers && (
        <NavItem routeName="vacancies"><Text en="Careers" /></NavItem>
      )}
      <NavItem routeName="contact"><Text en="Contact" /></NavItem>
      {children}
    </StyledNav>
  );
}
