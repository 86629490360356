import React from 'react';
import { styled } from 'styled-components';
import Background from './Background';
import Col from './Col';
import Container from './Container';
import Flex from './Flex';
import Grid from './Grid';
import Heading from '../Heading';
import List from '../List';
import { FooterNav } from './Nav';
import NavItem from './NavItem';
import Socials from '../Socials';
import Text from '../Text';
import useVacancyCount from '../../hooks/useVacancyCount';

const StyledFooter = styled.footer`
  background-color: var(--color-downriver-400);
  color: var(--color-white);
  padding-top: var(--space-xxl);
  position: relative;

  .wave {
    margin-bottom: calc(var(--space-lg) * -1);
  }

  .wave1,
  .wave2 {
    fill: var(--color-downriver-300);
    position: relative;
    top: 3rem;
    transform-origin: center;
  }
`;

const FooterCloser = styled.div`
  background-color: var(--color-downriver-300);
  // needed for contrast
  color: white;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  padding-bottom: var(--space-xs);
  padding-top: var(--space-xs);
  position: relative;
`;

const FooterContainer = styled(Container)`
  padding-bottom: var(--space-lg);

  // make links large enough https://web.dev/tap-targets 
  li > a {
    padding: 0.5rem 0;
    display: inline-block;
  }
`;

export default function Footer() {
  const showCareers = useVacancyCount();

  return (
    <StyledFooter>
      <Background $withContainer $cols={{ sm: 2, lg: 3, xl: 4 }} $lineColor="downriver-300" $zIndex={1} />
      <FooterContainer>
        <Grid $cols={{ sm: 4, md: 8, lg: 12 }} $columnGap="gutter" $rowGap="baseline">
          <Col $display={{ sm: 'none', lg: 'block' }} $col={{ lg: 'span 4', xl: 'span 3' }} $padding="gutter">
            <Flex $height="100%" $direction={{ lg: 'column' }} $justify={{ lg: 'flex-end' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 36" className="wave"><path className="wave1" d="M5.37 36.938m1.374 0c2.969-2.578 7.896-8.265 9.886-18.95C18.32 9.239 23.72 2.74 30.843 1.022c5.501-1.342 11.275.753 14.593 5.296a11.86 11.86 0 0 1 2.732 10.232 11.646 11.646 0 0 1-4.517 6.827 5.165 5.165 0 0 0 .19-1.671c-.354-6.233-9.643-6.233-9.997 0 0 .093-.663 10.935 16.156 15.294z"></path><path className="wave2" d="M5.37 36.938H0c3.316-2.734 8.117-8.499 10.139-19.044C12.476 5.724 21.557-.853 29.927.288 22.837 2.272 17.42 8.927 15.714 17.8 13.629 28.736 8.56 34.22 5.733 36.547zM36.055 18.58a4.026 4.026 0 0 1 5.654.219h-.095a11.862 11.862 0 0 1-5.401 5.765 4.648 4.648 0 0 0-.158-5.984z"></path></svg>
            </Flex>
          </Col>
          <Col $col={{ sm: 'span 4', lg: 'span 4', xl: 'span 3' }} $padding="gutter">
            <FooterNav direction="vertical">
              <NavItem routeName="about"><Text en="About Tweede golf" /></NavItem>
              <NavItem routeName="pendulum"><Text en="Project Pendulum" /></NavItem>
              <NavItem routeName="opensource"><Text en="Open-source work" /></NavItem>
              <NavItem routeName="interop"><Text en="Rust interop guide" /></NavItem>
              <NavItem routeName="embedded-world"><Text en="Embedded World 2025" /></NavItem>
              {showCareers && (
                <NavItem routeName="vacancies"><Text en="Careers" /></NavItem>
              )}
              <NavItem routeName="privacy"><Text en="Privacy statement" /></NavItem>
              <NavItem as="a" target="_blank" href="https://tweedegolf.nl/images/tweede-golf-general-terms-2-2.pdf"><Text en="Terms" /></NavItem>
            </FooterNav>
          </Col>
          <Col $col={{ sm: 'span 4', lg: 'span 4', xl: 'span 3' }}>
            <Grid $cols={{ sm: 2, md: 1 }} $columnGap="gutter" $rowGap="baseline">
              <Col $col={{ sm: 'span 2', md: 'span 1' }} $padding="gutter">
                <Heading as="h4" $line="y" $lineColor="pastel-green-400" $color="pastel-green-400" $baseline>Tweede golf</Heading>
              </Col>
              <Col $col={{ sm: 'span 1' }} $padding="gutter">
                <Heading $line="y" $lineColor="pastel-green-400" as="strong" $baseline><Text en="Contact" /></Heading>
                <List>
                  <li><a href="tel:+31243010484">+31 24 30 10 484</a></li>
                  <li><a href="mailto:info@tweedegolf.nl">info@tweedegolf.nl</a></li>
                </List>
              </Col>
              <Col $col={{ sm: 'span 1' }} $padding="gutter">
                <Heading $line="y" $lineColor="pastel-green-400" as="strong" $baseline><Text en="Adres" /></Heading>
                <List>
                  <li>Castellastraat 26</li>
                  <li>6512 EX Nijmegen</li>
                  <li>The Netherlands</li>
                </List>
              </Col>
              <Col $col={{ sm: 'span 2', md: 'span 1' }} $padding="gutter">
                <Socials />
              </Col>
            </Grid>
          </Col>
        </Grid>
      </FooterContainer>
      <FooterCloser>
        <Background $withContainer $cols={{ sm: 2, lg: 4 }} $lineColor="downriver-400" $zIndex={1} />
        <Container>
          <Grid $cols={{ sm: 1, lg: 4 }} $columnGap="gutter" $rowGap="space-xs">
            <Col $padding="gutter"><strong>KvK</strong> 09202176</Col>
            <Col $padding="gutter"><strong>BTW</strong> NL820951183B01</Col>
            <Col $padding="gutter"><strong>IBAN</strong> NL27ABNA0551876433</Col>
            <Col $padding="gutter"><strong>© {new Date().getFullYear()}</strong> tweede golf | {VERSION?.slice(-6) || 'dev'}</Col>
          </Grid>
        </Container>
      </FooterCloser>
    </StyledFooter>
  );
}
