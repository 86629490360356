import React from 'react';
import { css, styled } from 'styled-components';
import { breakpoints } from '../../styled/utils';
import { BreakpointKey, Breakpoints } from '../../types';
import { container } from '../../styled/mixins';

interface BackgroundProps {
  $cols: Breakpoints,
  $lineColor?: string,
  $withContainer?: boolean,
  $zIndex?: number,
}

const StyledBackground = styled.div<BackgroundProps>`
  column-gap: var(--gutter);
  display: flex;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  user-select: none;
  width: 100%;
  z-index: ${props => props.$zIndex || '-1'};

  ${props => props.$withContainer && css`
    ${container}
    left: 50%;
    transform: translateX(-50%);
  `}

  > div {
    border-left: 1px solid var(--color-${props => props.$lineColor || 'iron-300'});
    display: none;
    flex: 1;

    ${props => props.$cols && Object.entries(props.$cols).map(([size, value]) => css`
      @media ${breakpoints[size as BreakpointKey]} {
        &:nth-child(-n+${value}) {
          display: block;
        }
      }
    `)}
  }
`;

export default function Background({ $cols, $lineColor, $zIndex, $withContainer }: BackgroundProps) {
  return (
    <StyledBackground $withContainer={$withContainer} $cols={$cols} $lineColor={$lineColor} $zIndex={$zIndex} >
      {[...Array(12)].map((e, i) => (
        <div key={i} />
      ))}
    </StyledBackground>
  );
}
