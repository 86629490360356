import React from 'react';
import { css, styled } from 'styled-components';
import { breakpoints } from '../styled/utils';
import { BreakpointKey, Breakpoints, Tag } from '../types';
import TagComponent from './Tag';

interface TagsProps {
  children?: React.ReactNode
  tags?: Tag[],
  justify?: Breakpoints,
}

interface StyledTagsProps {
  $justify?: Breakpoints,
}

const StyledTags = styled.div<StyledTagsProps>`
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs);

  ${props => props.$justify && Object.entries(props.$justify).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      justify-content: ${value}
    }
  `)}
`;

export default function Tags({children, tags, justify}: TagsProps) {
  if (tags) {
    tags.sort((a, b) => {
      if (a.language && b.language || !a.language && !b.language) {
        return 0
      } else if (a.language && !b.language) {
        return 1
      } else {
        return -1
      }
    })
  }

  return (
    <StyledTags $justify={justify}>
      {tags && tags.map((tag, index) => (
        tag.language
          ? (<TagComponent language={tag.name} key={index} slug={tag.name}>{tag.name}</TagComponent>)
          : (<TagComponent key={index} slug={tag.name}>{tag.name}</TagComponent>)
      ))}
      {children}
    </StyledTags>
  );
}
