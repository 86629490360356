import React from 'react';
import MarkdownComponent from './Markdown';

export interface TextProps {
    en: string,
    characters?: number,
    markdown?: boolean,
    options?: object,
}

export default function Text(props: TextProps) {
  const value = props.en;
  const dots = props.characters && value.length > props.characters ? '...' : '';

  // Markdown is not supported simultaneously with the shortened version to avoid breaking the formatting
  return props.markdown
    ? <MarkdownComponent suppressHydrationWarning children={value} options={props.options} />
    : <>{props.characters ? `${value.slice(0, props.characters)}${dots}` : value}</>
}
