import React, { useEffect } from 'react';
import { useRoute } from 'react-router5';
import Home from './pages/home/Home';
import NotFound from './NotFound';
import { RouterState } from '../routerMiddleware';
import { constants } from 'router5';
import { isProduction } from '../util';

export default function App() {
  const context = useRoute();
  const route = context.route as RouterState;

  useEffect(() => {
    setTimeout(() => {
      if (isProduction && window._paq) {
        window._paq.push(['setCustomUrl', route.path]);
        window._paq.push(['setDocumentTitle', window.document.title]);
        window._paq.push(['trackPageView']);
      }
    }, 1);
  }, [route?.path]);

  if (!route || route.name === constants.UNKNOWN_ROUTE || route.error === 404) {
    return <NotFound />;
  }

  if (route.name == 'home') {
    return <Home />
  }
    
  if (route.component) {
    const Component = route.component;
    return <Component />;
  }

  return <NotFound />
}
