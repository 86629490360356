import React, { Ref } from 'react';
import { styled } from 'styled-components';
import { Link as Router5Link } from 'react-router5';
import { BaseLinkProps } from 'react-router5/dist/BaseLink';
import { defaultLanguage } from '../routerMiddleware';

export interface LinkProps extends Omit<BaseLinkProps, 'router' | 'routeName' | 'routeParams'> {
  as?: string,
  ref?: Ref<HTMLElement> | undefined,
  $color?: string
  $underline?: boolean,
  routeName?: string,
  reload?: boolean,
  routeParams?: {
    [key: string]: string | number;
  };
}

const StyledLink = styled(Router5Link)<LinkProps>`
  color: ${props => props.color ? `var(--color-${props.color})` : 'inherit'};
  text-decoration: ${props => props.$underline ? 'underline' : 'inherit'};
`;

export default function Link(props: LinkProps) {
  return (
    <StyledLink
      routeName={props.routeName}
      className={props.className}
      activeStrict={true}
      onClick={props.onClick}
      routeParams={{ ...(props.routeParams || {}), lang: defaultLanguage }}
      children={props.children}
      color={props.$color}
      $underline={props.$underline}
      title={props.title}
      routeOptions={{reload: props.reload}}
    />
  )
}
