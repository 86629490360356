import React from 'react';
import Button from './Button';
import Heading from './Heading';
import HeroAnimated from './HeroAnimated';
import Layout from './layout/Layout';
import Text from './Text';

export default function NotFound() {
  return (
    <Layout hero>
      <HeroAnimated animation="vector">
        <Heading $line="x"><Text en="Whoops, that page does not exist!" /></Heading>
        <Heading as="h2"><Text en="It's not you, it's us..." /></Heading>
        <Button $inverted routeName="home" reload={true}><Text en="Go to home" /></Button>
      </HeroAnimated>
    </Layout>
  );
}
