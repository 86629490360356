import React from 'react';
import { styled } from 'styled-components';
import Markdown from 'markdown-to-jsx';
import { breakpoints } from '../styled/utils';
import shield from '../../assets/images/shield.png';

interface MarkdownComponentProps {
  children: React.ReactNode,
  options?: object,
}

const MarkdownComponent = styled(Markdown) <MarkdownComponentProps>`
  >,
  > div {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul > li {
      code {
        background-color: var(--color-iron-300);
        border: 1px solid var(--color-iron-400);
        padding-left: .5em;
        padding-right: .5em;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      code {
        font-weight: 300;
        padding-left: 0.125em;
        padding-right: 0.125em;
      }
    }

    pre {
      background-color: var(--color-iron-300);
      border-radius: var(--border-radius);
      overflow-x: scroll;
      padding: var(--baseline) var(--gutter);

      .hljs {
        &::before {
          background-color: var(--color-iron-400);
          display: block;
          left: calc(var(--gutter) * -1);
          margin-bottom: var(--baseline);
          margin-top: calc(var(--baseline) * -1);
          padding: var(--space-sm) var(--gutter);
          position: relative;
          width: 100%;
        }

        &.lang-bash {
          &::before {
            content: 'Bash';
          }
        }

        &.lang-cpp {
          &::before {
            content: 'C++';
          }
        }

        &.lang-diff {
          &::before {
            content: 'Diff';
          }
        }

        &.lang-dockerfile {
          &::before {
            content: 'Dockerfile';
          }
        }

        &.lang-go {
          &::before {
            content: 'Go';
          }
        }

        &.lang-http {
          &::before {
            content: 'HTTP';
          }
        }

        &.lang-java {
          &::before {
            content: 'Java';
          }
        }

        &.lang-javascript {
          &::before {
            content: 'Javascript';
          }
        }

        &.lang-json {
          &::before {
            content: 'JSON';
          }
        }

        &.lang-kotlin {
          &::before {
            content: 'Kotlin';
          }
        }

        &.lang-nginx {
          &::before {
            content: 'nginx';
          }
        }

        &.lang-pgsql {
          &::before {
            content: 'pgSQL';
          }
        }

        &.lang-python {
          &::before {
            content: 'Python';
          }
        }

        &.lang-rust {
          &::before {
            content: 'Rust';
          }
        }

        &.lang-shell {
          &::before {
            content: 'Shell';
          }
        }

        &.lang-c {
          &::before {
            content: 'C';
          }
        }

        &.lang-cpp {
          &::before {
            content: 'C++';
          }
        }

        &.lang-toml {
          &::before {
            content: 'TOML';
          }
        }

        &.lang-typescript {
          &::before {
            content: 'Typescript';
          }
        }

        &.lang-xml {
          &::before {
            content: 'XML';
          }
        }

        &.lang-yaml {
          &::before {
            content: 'YAML';
          }
        }

        &.lang-armasm {
          &::before {
            content: 'Assembly';
          }
        }
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid var(--color-iron-400);
      font-size: var(--font-size-sm);
      width: 100%;

      thead {
        background-color: var(--color-iron-200);
      }

      tbody {
        background-color: var(--color-white);
      }

      th {
        font-weight: 500;
      }

      th,
      td {
        border-right: 1px solid var(--color-iron-400);
        padding: calc(var(--baseline) / 2) var(--gutter);
        text-align: left !important;
      }

      tr {
        border-bottom: 1px solid var(--color-iron-400);
      }
    }

    ul {
      list-style-type: disc;
      padding-left: 1rem;

      li {
        &::marker {
          color: var(--color-pastel-green-400);
        }
      }
    }

    ol {
      list-style-position: inside;
      list-style-type: decimal;

      li {
        &::marker {
          font-weight: 500;
        }
      }
    }

    img {
      height: auto;
      max-width: 100%;
      vertical-align: middle;
    }

    iframe {
      aspect-ratio: 16/9;
      background-color: var(--color-downriver-400);
      border-radius: var(--border-radius);
      overflow: hidden;
      width: 100%;
    }

    blockquote {
      padding-bottom: var(--baseline);
      padding-top: var(--baseline);
      position: relative;
      
      font-style: italic;
      font-size: 1.125rem;

      cite {
        font-style: normal;
        font-size: 1rem;
      }

      &::before,
      &::after {
        border-bottom: 1px solid var(--color-pastel-green-400);
        border-top: 1px solid var(--color-pastel-green-400);
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: var(--gutter);
      }

      &::before {
        border-left: 1px solid var(--color-pastel-green-400);
        transform: translateX(-100%);
      }

      &::after {
        border-right: 1px solid var(--color-pastel-green-400);
        right: 0;
        transform: translateX(100%);
      }
    }

    *:not(:last-child):not(li) {
      margin-bottom: var(--baseline);
    }

    section {
      background-color: var(--color-downriver-400);
      border-radius: var(--border-radius);
      color: white;
      margin-bottom: var(--baseline-lg) !important;
      margin-top: var(--baseline);
      overflow: hidden;
      padding-block: var(--space-xl);
      padding-inline: var(--gutter);
      position: relative;
      z-index: 0;

      @media ${breakpoints.lg} {
        padding-block: var(--space-lg);
        padding-inline: var(--gutter);
      }

      &::after {
        background-image: url(${shield});
        background-position: right -25% center;
        background-repeat: no-repeat;
        background-size: auto 125%;
        content: '';
        inset: 0;
        opacity: 0.25;
        pointer-events: none;
        position: absolute;
        z-index: -1;

        @media ${breakpoints.lg} {
          opacity: 1;
        }
      }

      > * + * {
        margin-top: var(--baseline);
      }

      > p:last-child > a {
        background-color: var(--color-white);
        border-radius: var(--border-radius);
        border: 1px solid transparent;
        color: var(--color-downriver-400);
        cursor: pointer;
        display: inline-block;
        font-weight: 500;
        line-height: 1;
        padding: .75rem 1.5rem;
        text-align: center;
        text-decoration: none;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;
      }

      > span {
        color: var(--color-iron-400);
        display: block;
        position: absolute;
        top: 0.5rem;
        left: 50%;
        translate: -50%;
        font-size: var(--font-size-sm);
      }

      > p + ul,
      > span + h2 {
        margin-top: 0;
      }

      > ul {
        list-style-position: inside;
        list-style-type: circle;

        li::marker {
          color: var(--color-pastel-green-400);
        }
      }
    }
  }

  sup {
    font-size: var(--font-size-sm);
    vertical-align: super;
  }

  a {
    text-decoration-color: var(--color-pastel-green-400);
    text-decoration: underline;
    cursor: pointer;
  }
  
  a[href^="#"] {
    text-decoration: none;
  }

  hr {
    display: none;
  }
`;

export default MarkdownComponent;
