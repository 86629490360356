import React from 'react';
import { styled } from 'styled-components';
import Baseline from './layout/Baseline';
import CanvasStar from './CanvasStar';
import CanvasVector from './CanvasVector';
import Container from './layout/Container';
import Grid from './layout/Grid';
import Col from './layout/Col';

interface HeroAnimatedProps {
  children: React.ReactNode,
  animation: string,
}

const StyledHeroAnimated = styled.div`
  background-color: var(--color-downriver-400);
  color: var(--color-white);
  padding-bottom: var(--space-xxl);
  padding-top: var(--space-xxl);
  position: relative;
`;

const StyledContainer = styled(Container)`
  user-select: none;
  position: relative;
  z-index: 1;
`;

export default function HeroAnimated({ children, animation }: HeroAnimatedProps) {
  return (
    <StyledHeroAnimated suppressHydrationWarning>
      {animation === 'vector' && <CanvasVector />}
      {animation === 'star' && <CanvasStar />}
      <StyledContainer>
        <Grid $cols={{ xl: 12 }}>
          <Col $col={{ xl: 'span 6' }}>
            <Baseline>
              {children}
            </Baseline>
          </Col>
        </Grid>
      </StyledContainer>
    </StyledHeroAnimated>
  );
}
