import React from 'react';
import Text from '../../Text';

export default function AboutBelief() {
  return (
    <React.Fragment>
      <p>
        <Text
          en="Now that our lives have become highly digitalized and we depend heavily on digital infrastructures, we are experiencing the consequences of unsafe software and poorly protected data every day. It is time to make software safer."
        />
      </p>
      <p>
        <Text
          en="We believe that the design of safe and privacy-friendly software is the responsibility of all software builders, whether you are a freelancer, an agency or big tech. Security and privacy thus become the domain of software makers, not just that of pen testers and consultants."
        />
      </p>
      <p>
        <Text
          en="An ounce of prevention is worth a pound of cure - even more so in software."
        />
      </p>
    </React.Fragment>
  );
}
