import React from 'react';
import { ThemeProvider } from 'styled-components';
import AboutBelief from '../../texts/about/AboutBelief';
import BlogCard from '../blog/BlogCard';
import Button from '../../Button';
import Clients from '../../Clients';
import Container from '../../layout/Container';
import ContentHighlighted from '../../ContentHighlighted';
import Flex from '../../layout/Flex';
import Grid from '../../layout/Grid';
import Heading from '../../Heading';
import Hero from '../../Hero';
import Layout from '../../layout/Layout';
import Link from '../../Link';
import List from '../../List';
import Paragraph from '../../Paragraph';
import Sections from '../../layout/Sections';
import useArticles from '../../../hooks/useArticles';
import useClients from '../../../hooks/useClients';
import Text from '../../Text';
import { Seo } from '../../seo/Seo';

export default function Home() {
  const title = 'Home - Tweede golf';
  const description = 'Software must become safer. We develop secure and robust software for digital infrastructure.';

  return (
    <Layout hero>
      <Seo title={title} description={description} />
      <ThemeProvider theme={{ color: 'pastel-green-400' }}>
        <Hero illustration="pastel-green-400">
          <Heading $line="x">
            Software must
            <br/>
            become safer.
          </Heading>
          <div>
            <strong><Text en="That's why we work on projects like:" /></strong>
            <List $border>
              <li>
                <Paragraph $line="y">
                  creating safe internet infrastructure with <em>Rust</em>
                </Paragraph>
              </li>
              <li>
                <Paragraph $line="y">
                  securing connected devices with <em>Embedded Rust</em>
                </Paragraph>
              </li>
              <li>
                <Paragraph $line="y">
                  helping organizations worldwide to adopt Rust
                </Paragraph>
              </li>
            </List>
          </div>
          <Button $ghost routeName="expertises.rust-engineering"><Text en="Work with us" /></Button>
        </Hero>
      </ThemeProvider>
      <Sections $rowGap="space-xxl">
        <ContentHighlighted>
          <Heading $line="x" as="h2" $color="white">
            <Text
              en="Software has been built badly for the past 10 years"
            />
          </Heading>
          <div>
            <Heading as="strong" $line="y" $lineColor="pastel-green-400" $color="pastel-green-400">
              <Text
                en="That's why these days, our industry is spending most of its time patching the leaks."
              />
            </Heading>
          </div>
          <AboutBelief />
          <Button $ghost $inverted routeName={'expertises.rust-engineering'}><Text en="Let's fix it" /></Button>
        </ContentHighlighted>

        <Clients clients={useClients(['rustfoundation', 'stf', 'isrg', 'ly', 'sidn', 'dis', 'amsterdam', 'ictu', 'nlnetlabs', 'ferrous', 'technolution', 'gama'])} />

        <Container>
          <Sections $rowGap="baseline">
            <Flex $direction={{ sm: 'column', md: 'row' }} $align={{ md: 'flex-end' }} $justify={{ md: 'space-between' }} $rowGap="baseline-sm">
              <div>
                <Heading as="h2" $baseline><Text en="Thought leadership" /></Heading>
                <p>Tech blog on <strong>web</strong>, <strong>security</strong> & <strong>embedded</strong></p>
              </div>
              <Link className="medium" routeName="blog"><Text en="Show all" /></Link>
            </Flex>
            <Grid $cols={{ lg: 2 }} $columnGap="gutter" $rowGap="baseline">
              {useArticles([120, 122]).map((article, index) => (
                <BlogCard key={article.id} position={index} article={article} authors={article.authors} />
              ))}
            </Grid>
          </Sections>
        </Container>

      </Sections>
    </Layout>
  );
}
